import { AbstractControl, Validators } from '@angular/forms';
import { Utils } from '@klickdata/core/util';
export class ObjectValidator extends Validators {
    public static validTranslation(control: AbstractControl): { [key: string]: boolean } {
        return control.value && ObjectValidator.isValid(control.value) ? null : { invalidObject: true };
    }

    public static isValid(obj: { [key: string]: any }, ignoreKeys: string[] = []): boolean {
        ObjectValidator.trim(obj, ignoreKeys);
        return !!Object.keys(obj).length;
    }

    /**
     *
     * @param obj Object to validate
     * @param ignoreKeys key to be ignored or removed during validation process.
     * @param someKeys key to check when object is valid and some of this keys exists.
     * @returns
     */
    public static isValidKeys(obj: { [key: string]: any }, ignoreKeys: string[] = [], someKeys: string[]): boolean {
        ObjectValidator.trim(obj, ignoreKeys);
        return ObjectValidator.isValid && someKeys.some((key) => obj.hasOwnProperty(key));
    }

    private static trim(obj: { [key: string]: any }, ignoreKeys: string[] = []): { [key: string]: any } {
        Object.entries(obj).forEach((entry: [string, string]) => {
            const key = entry[0];
            const value = entry[1];
            if (ignoreKeys.includes(key) || Utils.isEmpty(value)) {
                delete obj[key];
            }
        });
        return obj;
    }

    public static isDirty(obj: { [key: string]: any }, keys: string[] = []): boolean {
        return (
            obj &&
            Object.keys(obj)
                .filter((key) => keys.includes(key))
                .some((key) => !Utils.isEmpty(obj[key]))
        );
    }
}
