import { Config, environmentBase } from '@klickdata/core/config';

export const environment: Config = {
    ...environmentBase,
    buildMode: 'feature',
    PAYPAL_MODE: 'sandbox',

    apiUrl: 'https://feature.store.klickdata.k3.io/app/',
    resourceUrl: 'https://feature.store.klickdata.k3.io/resources/',
    scormPlayer: 'https://feature.store.klickdata.k3.io/player/',
    playerUrl: 'https://staging.player.klickdata.k3.io/',
    messageRecipient: 'https://staging.player.klickdata.k3.io/',
    jwtWhitelist: ['feature.store.klickdata.k3.io'],

    WS_ECHO_SERVER: 'staging.socket.klickdata.k3.io',
    WS_ECHO_AUTH: 'https://feature.store.klickdata.k3.io/broadcasting/auth',
    domains: ['klickdata.k3.io'],
};
