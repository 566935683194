import { MatDateFormats } from '@angular/material/core';

export const APP_MOMENT_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'L',
    },
    display: {
        dateInput: 'L',
        monthYearLabel: 'YYYY-MM',
        dateA11yLabel: 'L',
        monthYearA11yLabel: 'YYYY-MMMM',
    },
};
