import { Pipe, PipeTransform } from '@angular/core';
import { ResourceType, ResourceTypes, AppScope } from '@klickdata/core/resource';

@Pipe({
    name: 'typeFontIcon',
})
export class TypeFontIconPipe implements PipeTransform {
    transform(type_id: number, scope_id?: AppScope) {
        switch (scope_id || ResourceTypes.scopeByChildType(type_id)) {
            case AppScope.COURSE:
                return 'kd-icon-task';
            case AppScope.TEST:
                return 'kd-icon-test';
            case AppScope.E_COURSE:
                return 'kd-icon-player';
            case AppScope.SURVEY:
                return 'kd-icon-survey';
            case AppScope.MATERIAL:
                return 'kd-icon-document';
            case AppScope.USERS:
                return 'kd-icon-user';
            case AppScope.GROUPS:
                return 'groups';
            case AppScope.CATEGORIES:
                return 'folder';
            case AppScope.TAGS:
                return 'tag';
            case AppScope.CUSTOMERS:
                return 'supervised_user_circle';
            case AppScope.SECTIONS:
                return 'widgets';
            case AppScope.FOLDERS:
                return 'folder_open';
            case AppScope.SCORM:
                return 'business_center';
        }
    }
}
