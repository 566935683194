import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MatMomentDateModule,
    MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { AppErrorHandler } from '@klickdata/core/application/src/app-error-handler.service';
import { APP_MOMENT_DATE_FORMATS } from '@klickdata/core/application/src/dates/app-date-format';
import { AuthModule } from '@klickdata/core/auth';
import { APP_CONFIG_TOKEN } from '@klickdata/core/config';
import { HttpLocaleInterceptor } from '@klickdata/core/http';
import { MessageModule } from '@klickdata/core/message';
import { UserIdleModule } from 'angular-user-idle';
import { Angulartics2Module } from 'angulartics2';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SelectivePreloadingStrategy } from './core/preload/selective-preloading-strategy';
import { SharedModule } from './shared/shared.module';

export function tokenGetter() {
    return localStorage.getItem('token');
}

declare const require; // Use the require method provided by webpack

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: environment.jwtWhitelist,
            },
        }),

        MatMomentDateModule,

        CoreModule.forRoot(),
        AuthModule,
        MessageModule,
        SharedModule,
        AppRoutingModule,
        Angulartics2Module.forRoot(),
        ServiceWorkerModule.register('./ngsw-worker.js', {
            enabled: environment.buildMode === 'production',
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.buildMode === 'production',
        }),
        UserIdleModule.forRoot({
            idle: environment.idle,
            ping: environment.pingToken,
            idleSensitivity: environment.idleSensitivity,
            timeout: 10,
        }),
    ],
    providers: [
        { provide: APP_CONFIG_TOKEN, useValue: environment },
        { provide: ErrorHandler, useClass: AppErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: HttpLocaleInterceptor, multi: true },

        // { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: APP_MOMENT_DATE_FORMATS },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },

        MatMomentDateModule,
        SelectivePreloadingStrategy,
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {}
}
