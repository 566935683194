import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@klickdata/core/auth';
import { MobileService, SideNaveDataTypes } from '@klickdata/core/mobile';
import { Downloads } from '@klickdata/core/resource/src/download.model';
import { DownloadPdfDialogComponent } from './download-pdf-dialog.component';
import { DownloadService } from './download.service';

@Injectable({ providedIn: 'root' })
export class DownloadHelperService {
    constructor(
        protected dialog: MatDialog,
        protected downloadService: DownloadService,
        protected mobileService: MobileService,
        protected auth: AuthService
    ) {}

    public download(downloads: Downloads, queryParam?: { [key: string]: (number | string)[] }) {
        if (downloads.data.length > 1) {
            if (!this.auth.checkPlatform('player')) {
                this.mobileService.updateSideNavSub({
                    dataType: SideNaveDataTypes.DOWNLOAD,
                    data: downloads,
                });
            } else {
                const dialogRef = this.dialog.open(DownloadPdfDialogComponent, {
                    width: '70%',
                    data: downloads,
                    disableClose: true,
                    panelClass: 'sheet-wrapper',
                });
            }
        } else if (queryParam) {
            this.downloadService.download(downloads.data[0].url, queryParam).subscribe((data) => {
                const blob = new Blob([data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                window.open(url, '_blank');
            });
        } else {
            window.open(downloads.data[0].url, '_blank');
        }
    }
}
