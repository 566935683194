import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, EMPTY, Observable, Subject, timer } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApplicationService implements OnDestroy {
    protected notificationSubject: BehaviorSubject<string[]>;
    protected notifications: string[] = [];
    protected version: BehaviorSubject<{ version: string; date: string }>;
    protected nVersion: BehaviorSubject<{ version: string; date: string }>;
    protected destroy: Subject<boolean> = new Subject();
    private _headerVisibilitySubj: Subject<boolean> = new Subject();

    constructor(protected http: HttpClient) {
        this.notificationSubject = new BehaviorSubject<string[]>([]);
        this.nVersion = new BehaviorSubject(null);
        try {
            this.version = new BehaviorSubject(JSON.parse(localStorage.getItem('nk3-version')));
        } catch (e) {
            this.version = new BehaviorSubject(null);
        }
        this.startVersionCheck();
    }

    /**
     *
     * @returns {Observable<string[]>}
     */
    getNotifications(): Observable<string[]> {
        return this.notificationSubject.asObservable();
    }

    public notify(notificationName: string, data?: any) {
        this.notifications.push(notificationName);
        this.notificationSubject.next(this.notifications);
    }

    public removeNotification(notificationName: string) {
        this.notifications = this.notifications.filter((item) => item !== notificationName);
        this.notificationSubject.next(this.notifications);
    }

    public reload() {
        // window.location.reload();
        location.reload();
        localStorage.removeItem('nk3-version');
    }

    public getVersion(): Observable<{ version: string; date: string }> {
        return this.version.asObservable();
    }
    public getNewVersion(): Observable<{ version: string; date: string }> {
        return this.nVersion.asObservable();
    }

    public getVersionValue(): string {
        return this.version?.value?.version || this.nVersion?.value?.version || 'None';
    }

    protected notificationIsActive(notificationName): boolean {
        return this.notifications.indexOf(notificationName) > -1;
    }

    protected startVersionCheck(): void {
        timer(0, 100000)
            .pipe(
                switchMap(() =>
                    this.http.get<{ version: string; date: string }>(`version.json?${moment().format('DDMMYYHHmmSS')}`)
                ),
                catchError(() => EMPTY),
                takeUntil(this.destroy)
            )
            .subscribe((data) => {
                if (data && !this.version.value?.version) {
                    localStorage.setItem('nk3-version', JSON.stringify(data));
                    this.version.next(data);
                }
                if (
                    this.version.value?.version &&
                    this.version.value.version !== data?.version &&
                    !this.notificationIsActive('version-alert')
                ) {
                    this.nVersion.next(data);
                    this.notify('version-alert');
                }
            });
    }
    public setHeaderVisisbility(visible: boolean) {
        this._headerVisibilitySubj.next(visible);
    }
    public getHeaderVisibility(): Observable<boolean> {
        return this._headerVisibilitySubj.asObservable();
    }
    public ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
